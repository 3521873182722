<template>
  <div>
    TeacherAttendanceJournal
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {getCookie} from "@/utils/helpers/cookies.helpers";

export default {
  name: 'TeacherAttendanceJournal',
  data(){
    return {
      pps_id: this.$route.query.pps_id || getCookie("USER_ID")
    }
  },
  computed:{
    ...mapState('attendanceJournal', ['teacher_groups'])
  },
  methods:{
    ...mapActions('attendanceJournal', ['GET_TEACHER_GROUPS'])

  },
  async mounted(){
    await this.GET_TEACHER_GROUPS(this.pps_id)
    console.log(this.teacher_groups)
  }
}
</script>

<style scoped>

</style>